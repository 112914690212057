


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TMeetingRoomState } from '@/_modules/meeting-rooms/types/meeting-room-state.type';
import { TUser } from '@/_types/user.type';
import { TContact } from '@/_types/contact.type';
import MeetingRoom from '@/_modules/meeting-rooms/components/meeting-room/meeting-room.vue';
import BroadcastRoom from '@/_modules/meeting-rooms/components/broadcast-room/broadcast-room.vue';
import CompanyRoom from '@/_modules/meeting-rooms/components/company-room/company-room.vue';
import EventChat from '@/_modules/event-chat/components/event-chat/event-chat.vue';
import StreamPlayer from '@/_components/stream-player/stream-player.vue';
import FixedDraggable from '@/_components/fixed-draggable/fixed-draggable.vue';
import MeetingsHelper from '@/_helpers/meetings.helper';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { MeetingRoomType } from '@/_modules/meeting-rooms/types/meeting-room-type.enum';
import { TMediaItem } from '@/_modules/events/types/media-item.type';
import LiveMediaBlock from '@/_modules/promo/components/live-media-block/live-media-block.vue';
import { TLivePage } from '@/_types/promo-page/live-page.type';
import { NavigationGuardNext, Route } from 'vue-router';
import broadcastsService from '@/_services/broadcasts.service';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import PromoBroadcastSettings, { PromoBroadcastSettingsProgram } from '@/_modules/promo/components/promo-broadcast-settings/promo-broadcast-settings.vue';
import PromoBroadcastTimeCheck
  from '@/_modules/promo/components/promo-broadcast-time-check/promo-broadcast-time-check.vue';
import PromoChatroomTimeCheck
  from '@/_modules/promo/components/promo-chatroom-time-check/promo-chatroom-time-check.vue';
import MeetingCancelConfirmation from '@/_modules/promo/components/meeting-cancel-confirmation/meeting-cancel-confirmation.vue';
import MeetingDeclineConfirmation from '@/_modules/promo/components/meeting-decline-confirmation/meeting-decline-confirmation.vue';
import { TEvent } from '@/_types/event.type';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import EmbedCodeForm from '@/_modules/events/components/embed-code-form/embed-code-form.vue';
import MediaBlockItem from '@/_modules/events/components/media-block-item/media-block-item.vue';
import PromoNotification from '@/_modules/promo/components/promo-notification/promo-notification.vue';
import { TNotification } from '@/_modules/promo/types/notification.type';
import { POPUP_NOTIFICATION_ANIMATION_TIME } from '@/_modules/promo/store/notifications.store';
import eventDiscoveryService from '@/_services/event-discovery.service';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';
import PromoNotificationMeeting from '@/_modules/promo/components/promo-notification-meeting/promo-notification-meeting.vue';
import PromoNotificationNewsArticle from '@/_modules/promo/components/promo-notification-news-article/promo-notification-news-article.vue';
import { TDiscoveryWaitingMeetingNotificationResponse } from '@/_types/discovery/discovery-waiting-meeting-notification-response.type';
import { TDiscoveryNewsArticleNotificationResponse } from '@/_types/discovery/discovery-news-article-notification-response.type';

// See https://class-component.vuejs.org/guide/additional-hooks.html
Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    MeetingRoom,
    BroadcastRoom,
    CompanyRoom,
    EventChat,
    StreamPlayer,
    FixedDraggable,
    MediaBlockItem,
    LiveMediaBlock,
    SimplePopup,
    PromoBroadcastSettings,
    PromoBroadcastTimeCheck,
    PromoChatroomTimeCheck,
    EmbedCodeForm,
    PromoNotification,
    PromoNotificationMeeting,
    PromoNotificationNewsArticle,
    MeetingCancelConfirmation,
    MeetingDeclineConfirmation,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      isAuthenticated: 'authStore/isAuthenticated',
      user: '_userStore/user',
      contact: 'promoPageStore/contact',
      broadcasts: 'meetingRoomsStore/broadcasts',
      meetings: 'meetingRoomsStore/meetings',
      companyVideoChats: 'meetingRoomsStore/companyVideoChats',
      unpinnedMediaItems: 'promoStore/unpinnedMediaItems',
      programUnpinnedMediaItems: 'promoProgramStore/unpinnedMediaItems',
      obsSettingsDialogConfig: '_eventStore/obsSettingsDialogConfig',
      zoomSettingsDialogConfig: '_eventStore/zoomSettingsDialogConfig',
      embedCodeDialogConfig: '_eventStore/embedCodeDialogConfig',
      isBroadcastTimeCheckDialogVisible: '_eventStore/isBroadcastTimeCheckDialogVisible',
      isChatRoomTimeCheckDialogVisible: '_eventStore/isChatRoomTimeCheckDialogVisible',
      popupNotifications: 'notificationsStore/popupNotifications',
      waitingMeetingNotification: 'notificationsStore/waitingMeetingNotification',
      newsArticleNotification: 'notificationsStore/newsArticleNotification',
      getCompanyVideoChatStateByExternalId: 'meetingRoomsStore/getCompanyVideoChatStateByExternalId',
      isMeetingTooEarlyPopupVisible: 'meetingsStore/isMeetingTooEarlyPopupVisible',
      isMeetingCancelPopupVisible: 'meetingsStore/isMeetingCancelPopupVisible',
      isMeetingDeclinePopupVisible: 'meetingsStore/isMeetingDeclinePopupVisible',
      livePageData: 'cabinetLobbyStore/livePageData',
      isIntroMediaUnpinned: 'cabinetLobbyStore/isIntroMediaUnpinned',
      isAgendaMediaUnpinned: 'cabinetLobbyStore/isAgendaMediaUnpinned',
    }),
  },
})
export default class Event extends Vue {

  public readonly popupNotificationAnimationTime: number = POPUP_NOTIFICATION_ANIMATION_TIME / 1000;
  public readonly PromoBroadcastSettingsProgram: typeof PromoBroadcastSettingsProgram = PromoBroadcastSettingsProgram;
  public readonly BroadcastType: typeof BroadcastType = BroadcastType;

  public readonly isAuthenticated: boolean;
  public readonly user: TUser;
  public readonly contact: TContact;
  public readonly event: TEvent;
  public readonly meetings: TMeetingRoomState[];
  public readonly broadcasts: TMeetingRoomState[];
  public readonly companyVideoChats: TMeetingRoomState[];
  public readonly unpinnedMediaItems: TMediaItem[];
  public readonly programUnpinnedMediaItems: TMediaItem[];
  public readonly livePageData: TLivePage;
  public readonly isIntroMediaUnpinned: boolean;
  public readonly isAgendaMediaUnpinned: boolean;
  public readonly obsSettingsDialogConfig: TMeetingRoomConfig;
  public readonly zoomSettingsDialogConfig: TMeetingRoomConfig;
  public readonly embedCodeDialogConfig: TMeetingRoomConfig;
  public readonly isBroadcastTimeCheckDialogVisible: boolean;
  public readonly isChatRoomTimeCheckDialogVisible: boolean;
  public readonly popupNotifications: TNotification[];
  public readonly waitingMeetingNotification: TDiscoveryWaitingMeetingNotificationResponse;
  public readonly newsArticleNotification: TDiscoveryNewsArticleNotificationResponse;
  public readonly getCompanyVideoChatStateByExternalId: (externalId: string) => TCompanyVideoChatState;
  public readonly isMeetingTooEarlyPopupVisible: boolean;

  private destroyed$: Subject<void> = new Subject<void>();
  private meetingInviteCheck$: Subject<void> = new Subject<void>();

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get contactId(): number {
    return (this.contact && this.contact.id) || null;
  }

  public get meetingInviteKey(): string {
    return this.$route.params.inviteKey;
  }

  public created(): void {
    this.meetingInviteCheck$.pipe(
      takeUntil(this.destroyed$),
      debounceTime(1000),
    ).subscribe(() => {
      this.meetingInviteCheck();
    });

    // this.$store.dispatch('notificationsStore/simulate');
  }

  public beforeDestroy(): void {
    this.meetingInviteCheck$.complete();
    broadcastsService.disableActiveBroadcastsCheck();
    this.configureEventDiscoveryService({ eventId: null, contactId: null });
    this.configureChatStore({ eventId: null, contactId: null });
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get hasAccess(): boolean {
    return (this.event && this.event.personal && this.event.personal.has_access) || false;
  }

  public onOBSSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setObsSettingsDialogConfig', null);
  }

  public onZoomSettingsDialogClose(): void {
    this.$store.dispatch('_eventStore/setZoomSettingsDialogConfig', null);
  }

  public onEmbedCodeDialogClose(): void {
    this.$store.dispatch('_eventStore/setEmbedCodeDialogConfig', null);
  }

  public onBroadcastTimeCheckDialogClose(): void {
    this.$store.dispatch('_eventStore/setIsBroadcastTimeCheckDialogVisible', false);
  }

  public onChatRoomTimeCheckDialogClose(): void {
    this.$store.dispatch('_eventStore/setIsChatRoomTimeCheckDialogVisible', false);
  }

  public beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (to.params && to.params.forceAuthPageRoute) {
      next();
    } else if (to.name === 'auth-page' && !this.isAuthenticated) {
      /* show log-in popup instead: */
      this.$store.dispatch('authStore/setAuthPopupVisible', {
        isAuthPopupVisible: true,
        targetRoute: to.params && to.params.redirect ? to.params.redirect : null,
      });
    } else {
      next();
    }
  }

  @Watch('isAuthenticated', { immediate: true })
  private onIsAuthenticatedChange(): void {
    this.updateMeetingRoomsStore();
    this.configureEventDiscoveryService({ eventId: null, contactId: null });
    this.configureChatStore();
  }

  @Watch('contactId', { immediate: true })
  private onContactIdChange(): void {
    this.updateMeetingRoomsStore();
    this.configureEventDiscoveryService();
    this.configureChatStore();
  }

  @Watch('eventId', { immediate: true })
  private onEventIdChange(): void {
    const eventId = this.eventId;

    this.$store.dispatch('promoStore/clearPromoCompanies');
    this.$store.dispatch('_messageStore/setEventId', eventId);
    this.updateMeetingRoomsStore();
    this.updatePromoProgramStore();
    this.updateMeetingsStore();
    this.updateContactsStore();

    this.meetingInviteCheck$.next();
    if (eventId) {
      broadcastsService.enableActiveBroadcastsCheck(eventId);
    } else {
      broadcastsService.disableActiveBroadcastsCheck();
    }
    this.configureEventDiscoveryService();
    this.configureChatStore();
  }

  @Watch('meetingInviteKey', { immediate: true })
  private onMeetingInviteKeyChange(): void {
    this.meetingInviteCheck$.next();
  }

  @Watch('user', { immediate: true })
  private onUserChange(): void {
    this.meetingInviteCheck$.next();
    this.updatePromoProgramStore();
    this.updateMeetingsStore();
  }

  @Watch('contact', { immediate: true })
  private onContactChange(newContact: TContact, oldContact: TContact): void {
    this.meetingInviteCheck$.next();
    if (!oldContact && newContact) {
      this.updatePromoProgramStore();
    }
  }

  @Watch('hasAccess')
  private onHasAccessChange(): void {
    this.updatePromoProgramStore();
    this.meetingInviteCheck$.next();
  }

  // @Watch('unpinnedMediaItems', { immediate: true, deep: true })
  // private onUnpinnedMediaItemsChange(): void {
  //   console.log('onUnpinnedMediaItemsChange', this.unpinnedMediaItems);
  // }

  // @Watch('programUnpinnedMediaItems', { immediate: true, deep: true })
  // private onProgramUnpinnedMediaItemsChange(): void {
  //   console.log('onProgramUnpinnedMediaItemsChange', this.programUnpinnedMediaItems);
  // }

  private updateContactsStore(): void {
    this.$store.dispatch('contactsStore/setEventId', this.eventId);
  }

  private updateMeetingsStore(): void {
    const userId = (this.user && this.user.id) || null;
    const eventId = this.eventId;
    this.$store.dispatch('meetingsStore/setEventId', eventId);
    if (userId) {
      this.$store.dispatch('meetingsStore/requestUserMeetings', { userId });
    }
  }

  private async meetingInviteCheck(): Promise<void> {
    const meetingInviteKey = this.meetingInviteKey;
    const contactId = this.contactId;
    if (
      (this.$route.name !== 'meeting-invite' && this.$route.name !== 'company-video-chat-invite')
      || !contactId
      || !meetingInviteKey
    ) {
      return;
    }

    const eventId = this.eventId;
    const meetingInvite = MeetingsHelper.parseMeetingInviteKey(meetingInviteKey);
    if (!meetingInvite || !meetingInvite.type || meetingInvite.eventId !== eventId) {
      // TODO: show invalid invitation key info popup?
      return;
    }

    if (meetingInvite.type === MeetingRoomType.MEETING) {
      if (!meetingInvite.meetingId || !meetingInvite.meetingDate) {
        // TODO: show invalid invitation key info popup?
        return;
      }
      const meetingRoomConfig: TMeetingRoomConfig = {
        type: MeetingRoomType.MEETING,
        eventId: eventId,
        meetingId: meetingInvite.meetingId,
        contactId: contactId,
        meetingDate: meetingInvite.meetingDate,
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    }

    if (meetingInvite.type === MeetingRoomType.COMPANY) {
      if (!meetingInvite.moderatorContactId || !meetingInvite.externalId) {
        // TODO: show invalid invitation key info popup?
        return;
      }
      let videoChatState = this.getCompanyVideoChatStateByExternalId(meetingInvite.externalId);
      if (!videoChatState) {
        await new Promise<void>((resolve) => {
          const maxChecks = 6;
          let checkNumber = 0;
          const checkInterval = window.setInterval(() => {
            checkNumber++;
            videoChatState = this.getCompanyVideoChatStateByExternalId(meetingInvite.externalId);
            if (videoChatState) {
              window.clearInterval(checkInterval);
              resolve();
            }
            if (checkNumber >= maxChecks) {
              window.clearInterval(checkInterval);
              resolve();
            }
          }, 1000);
        });
      }

      if (!videoChatState || meetingInvite.moderatorContactId !== videoChatState.moderatorContactId) {
        // TODO: show something to user?
        return;
      }
      const meetingRoomConfig = {
        type: MeetingRoomType.COMPANY,
        eventId: eventId,
        contactId: contactId,
        moderatorContactId: meetingInvite.moderatorContactId,
        externalId: meetingInvite.externalId,
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);
    }
  }

  private updateMeetingRoomsStore(): void {
    const eventId = this.eventId;
    const isAuthenticated = this.isAuthenticated;
    const contactId = this.contactId;
    this.$store.dispatch('meetingRoomsStore/setEventId', (isAuthenticated && contactId && eventId) || null);
  }

  private updatePromoProgramStore(): void {
    if (this.eventId && this.user) {
      this.$store.dispatch('promoProgramStore/loadProgram', this.eventId);
    } else {
      this.$store.dispatch('promoProgramStore/reset');
    }
  }

  private configureEventDiscoveryService(config?: { eventId: number; contactId: number }): void {
    const isAuthenticated = this.isAuthenticated;
    const contactId = this.contactId;
    const eventId = this.eventId;
    eventDiscoveryService.configure(config || {
      eventId: eventId,
      contactId: (isAuthenticated && contactId) || null,
    });
  }

  private configureChatStore(config?: { eventId: number; contactId: number }): void {
    const isAuthenticated = this.isAuthenticated;
    const contactId = this.contactId;
    const eventId = this.eventId;
    this.$store.dispatch('chatStore/configure', config || {
      eventId: eventId,
      contactId: (isAuthenticated && contactId) || null,
    });
  }

  private closeMeetingTooEarlyPopup(): void {
    this.$store.dispatch('meetingsStore/setMeetingTooEarlyPopupVisible', false);
  }
  private closeMeetingCancelPopup(): void {
    this.$store.dispatch('meetingsStore/setMeetingCancelPopupVisible', false);
  }

  private closeMeetingDeclinePopup(): void {
    this.$store.dispatch('meetingsStore/setMeetingDeclinePopupVisible', false);
  }
}

