


import Component from 'vue-class-component';
import { Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  name: 'meeting-decline-confirmation',
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
    }),
  }
})
export default class MeetingCancelConfirmation extends Vue {

  public accept(): void {
    this.$store.dispatch('meetingsStore/setMeetingDecline', true);
  }

  public cancel(): void {
    this.$store.dispatch('meetingsStore/setMeetingDeclinePopupVisible', false);
  }

}
