import { render, staticRenderFns } from "./event.html?vue&type=template&id=eb11b5ec&scoped=true&"
import script from "./event.vue?vue&type=script&lang=ts&"
export * from "./event.vue?vue&type=script&lang=ts&"
import style0 from "./event.scss?vue&type=style&index=0&id=eb11b5ec&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb11b5ec",
  null
  
)

export default component.exports