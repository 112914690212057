


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

export type TItemsMenuItem = {
  value: any;
  label: string | TranslateResult;
};

@Component({
  name: 'items-menu',
})
export default class ItemsMenu extends Vue {

  @Prop({ type: Array })
  public readonly items: TItemsMenuItem[];

  @Prop({ default: null })
  public readonly value: any;

  public onItemClick(item: TItemsMenuItem): void {
    this.$emit('choose', item.value);
  }

}
