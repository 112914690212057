


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import MeetingRoomsHelper from '@/_modules/meeting-rooms/helpers/meeting-rooms.helper';
import { TMeetingRoomConfig } from '@/_modules/meeting-rooms/types/meeting-room-config.type';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import EventHelper from '@/_helpers/event.helper';
import FormInputText from '@/_modules/controls/components/form-input-text/form-input-text.vue';
import IconDownload from '@/_modules/icons/components/icon-download.vue';
import { BroadcastType } from '@/_types/broadcasts/broadcast-type.enum';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';

export enum PromoBroadcastSettingsProgram {
  OBS = 'OBS',
  ZOOM = 'Zoom',
}

@Component({
  name: 'promo-broadcast-settings',
  components: {
    FormInputText,
    IconDownload,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
      promoPage: 'promoPageStore/promoPage',
      getProgramById: 'promoProgramStore/getProgramById',
    }),
  }
})
export default class PromoBroadcastSettings extends Vue {

  @Prop({ type: String })
  public readonly program: PromoBroadcastSettingsProgram;

  @Prop({ type: Object })
  public readonly config: TMeetingRoomConfig;

  public PromoBroadcastSettingsProgram: typeof PromoBroadcastSettingsProgram = PromoBroadcastSettingsProgram;
  public event: TEvent;
  public contact: TContact;
  public promoPage: TPromoPage;
  public readonly getProgramById: (programId: number) => TConferenceProgram;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isEmptyConferenceProgram(): boolean {
    return this.config && this.config.broadcastType === BroadcastType.PROGRAM_SPEAKER && !this.config.programId;
  }

  public get contactId(): number {
    return this.contact ? this.contact.id : null;
  }

  public get pushUrl(): string {
    return MeetingRoomsHelper.getBroadcastPushUrl();
  }

  public get pushKey(): string {
    if (!this.config) {
      return '';
    }
    return MeetingRoomsHelper.getBroadcastPushKey(
      this.config,
      EventHelper.getEventExpireTimestamp(this.event)
    );
  }

  public get viewUrl(): string {
    if (this.config.broadcastType === BroadcastType.PROMO_CONTACT) {
      if (!this.promoPage || !this.promoPage.external_id) {
        return '';
      }
      return location.origin + this.$router.resolve({
        name: 'promo-page-events-company',
        params: {
          eventId: '' + this.eventId,
          external_id: '' + this.promoPage.external_id,
        }
      }).href;
    } else if (this.config.broadcastType === BroadcastType.PROGRAM_SPEAKER) {
      const conferenceProgram = this.getProgramById(this.config.programId);
      const startDateMoment = (conferenceProgram && conferenceProgram.date_start)
        ? this.$moment(conferenceProgram.date_start)
        : null;
      if (!conferenceProgram || !startDateMoment) {
        return '';
      }
      return location.origin + this.$router.resolve({
        name: 'promo-program-date-program',
        params: {
          eventId: '' + this.eventId,
          date: startDateMoment.format(DateTimeFormat.DATE_TINY),
          programId: '' + conferenceProgram.id,
        }
      }).href;
    }

    return '';
  }

  public get manualURL(): string | TranslateResult {

    const lang: string = this.$route.params.lang;
    let OBSDocument: string;
    let ZOOMDocument: string;
    switch (lang) {
      case 'en':
        OBSDocument = '/documents/Instructions for setting up live streaming from OBS_EN.pdf';
        ZOOMDocument = '/documents/Instructions for setting up live streaming from Zoom_EN.pdf';
        break;
      case 'ru':
        OBSDocument = '/documents/Instructions for setting up live streaming from OBS_RU.pdf';
        ZOOMDocument = '/documents/Instructions for setting up live streaming from Zoom_RU.pdf';
        break;
      case 'uk':
        OBSDocument = '/documents/Instructions for setting up live streaming from OBS_UKR.pdf';
        ZOOMDocument = '/documents/Instructions for setting up live streaming from Zoom_UKR.pdf';
        break;
      case 'lt':
        OBSDocument = '/documents/Instructions for setting up live streaming from OBS_EN.pdf';
        ZOOMDocument = '/documents/Instructions for setting up live streaming from Zoom_EN.pdf';
        break;
      case 'zh':
        OBSDocument = '/documents/Instructions for setting up live streaming from OBS_EN.pdf';
        ZOOMDocument = '/documents/Instructions for setting up live streaming from Zoom_EN.pdf';
        break;
      default:
        OBSDocument = '/documents/Instructions for setting up live streaming from OBS_EN.pdf';
        ZOOMDocument = '/documents/Instructions for setting up live streaming from Zoom_EN.pdf';
    }

    switch (this.program) {

      case PromoBroadcastSettingsProgram.OBS:
        return OBSDocument;

      case PromoBroadcastSettingsProgram.ZOOM:
        return ZOOMDocument;

    }

    return null;
  }
}
