


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { T2DVector } from '@/_types/2d-vector.type';
import { IFixedDraggableChild } from '@/_types/fixed-draggable-child.interface';

@Component({
  name: 'fixed-draggable',
})
export default class FixedDraggable extends Vue {

  @Prop({ type: Boolean, default: true })
  public readonly isActive: boolean;

  @Prop({ type: Number })
  public readonly top: number;

  @Prop({ type: Number })
  public readonly left: number;

  @Prop({ type: Number })
  public readonly bottom: number;

  @Prop({ type: Number })
  public readonly right: number;

  public isDragging: boolean = false;
  public translateX: number = 0;
  public translateY: number = 0;

  private destroyed$: Subject<void> = new Subject<void>();
  private child: IFixedDraggableChild;
  private isMouseDown: boolean = false;
  private moveStart: T2DVector = { x: 0, y: 0 };
  private translateStart: T2DVector = { x: 0, y: 0 };

  public mounted(): void {
    this.setChild();
    this.subscribeToChild();
    this.subscribeToPageEvents();
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public get style(): string {
    if (!this.isActive) {
      return 'top: auto; left: auto; bottom: auto; right: auto; transform: none;';
    }

    return `
      top: ${( typeof this.top === 'undefined' ? 'auto' : (this.top + 'px') )};
      left: ${( typeof this.left === 'undefined' ? 'auto' : (this.left + 'px') )};
      bottom: ${( typeof this.bottom === 'undefined' ? 'auto' : (this.bottom + 'px') )};
      right: ${( typeof this.right === 'undefined' ? 'auto' : (this.right + 'px') )};
      transform: translateX(${Math.round(this.translateX)}px) translateY(${Math.round(this.translateY)}px);
    `;
  }

  @Watch('isActive', { immediate: false })
  private onIsActiveChanged(): void {
    this.translateX = 0;
    this.translateY = 0;
    this.applyBoundingConstraints();
  }

  private setChild(): void {
    if (
      !this.$slots.default
      || typeof this.$slots.default[0] === 'undefined'
      || !this.$slots.default[0].componentInstance
    ) {
      return;
    }
    const componentInstance = this.$slots.default[0].componentInstance as unknown;
    this.child = (componentInstance as IFixedDraggableChild);
  }

  private subscribeToChild(): void {
    if (!this.child || !this.child.dragZoneMouseDown$) {
      return;
    }
    this.child.dragZoneMouseDown$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onChildDragZoneMouseDown);
  }

  private onChildDragZoneMouseDown(event: MouseEvent): void {
    if (!this.isActive) {
      return;
    }
    this.isMouseDown = true;
    this.isDragging = false;
    this.translateStart = { x: this.translateX, y: this.translateY };
    this.moveStart = { x: event.clientX, y: event.clientY };
  }

  private subscribeToPageEvents(): void {
    fromEvent<MouseEvent>(document, 'mousemove')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentMouseMove);

    fromEvent<MouseEvent>(document, 'mouseup')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onDocumentMouseUp);

    fromEvent<Event>(window, 'resize')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.onWindowResize);
  }

  private onWindowResize(): void {
    this.applyBoundingConstraints();
  }

  private onDocumentMouseMove(event: MouseEvent): void {
    if (!this.isMouseDown) {
      return;
    }
    event.preventDefault();
    this.isDragging = true;
    this.translateX = this.translateStart.x + event.clientX - this.moveStart.x;
    this.translateY = this.translateStart.y + event.clientY - this.moveStart.y;
    this.applyBoundingConstraints();
  }

  private onDocumentMouseUp(event: MouseEvent): void {
    if (this.isDragging) {
      event.preventDefault();
    }
    this.isMouseDown = false;
    this.isDragging = false;
  }

  private applyBoundingConstraints(): void {
    const container = this.$refs.container as HTMLDivElement;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const { offsetTop, offsetLeft, offsetWidth, offsetHeight } = container;

    const minTranslateX = -1 * offsetLeft + 20;
    const maxTranslateX = minTranslateX + windowWidth - offsetWidth - 60;

    const minTranslateY = -1.0 * offsetTop + 20;
    const maxTranslateY = minTranslateY + windowHeight - offsetHeight - 60;

    this.translateX = Math.min(maxTranslateX, Math.max(minTranslateX, this.translateX));
    this.translateY = Math.min(maxTranslateY, Math.max(minTranslateY, this.translateY));
  }
}
