


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { Location } from 'vue-router';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import Avatar from '@/_components/avatar/avatar.vue';
import DateTimeHelper from '@/_helpers/date-time.helper';
import { ChatMessageType } from '@/_modules/chat/types/chat-message-type.enum';
import { TContact } from '@/_types/contact.type';
import { TEvent } from '@/_types/event.type';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';

@Component({
  name: 'chat-message',
  components: {
    Avatar
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      contact: 'promoPageStore/contact',
      contactById: 'contactsStore/contactById',
    }),
  }
})
export default class ChatMessage extends Vue {

  @Prop({ type: Object, default: null })
  public readonly message: TChatMessage;

  @Prop({type: Boolean, default: false})
  public readonly showDayRow: boolean;

  public readonly ChatMessageType: typeof ChatMessageType = ChatMessageType;

  public readonly contact: TContact;
  public readonly event: TEvent;
  public readonly contactById: (contactId: number) => TContact;

  // public created(): void {
  //   this.onMessageChange();
  // }

  public get eventId(): number {
    return (this.event && this.event.id) || null;
  }

  public get isMine(): boolean {
    return this.contact && this.messageContactId === this.contact.id;
  }

  public get time(): string {
    return DateTimeHelper.messageTime(this.message.time);
  }

  public get day(): string {
    return DateTimeHelper.messageDay(this.message.time, this.$i18n.locale);
  }

  public get messageContactId(): number {
    const contactIdFromContext: number = (this.message && this.message.data && this.message.data.contact_id ? this.message.data.contact_id : null);
    const contactIdFromMessage: number = (this.message && this.message.from ? this.message.from : null);
    return contactIdFromContext || contactIdFromMessage || null;
  }

  public get messageContact(): TContact {
    const messageContactId = this.messageContactId;
    return messageContactId ? this.contactById(messageContactId) : null;
  }

  public get messageAuthorFullName(): string {
    if (this.message && this.message.data && this.message.data.name) {
      return this.message.data.name;
    } else if (this.messageContact && this.messageContact.fullName) {
      return this.messageContact.fullName;
    }
    return (this.$t('contacts.info.noname') as string);
  }

  public get messageAuthorPhotoUrl(): string {
    if (this.message && this.message.data && this.message.data.photo_url) {
      return this.message.data.photo_url;
    } else if (this.messageContact && this.messageContact.photo_url) {
      return this.messageContact.photo_url;
    }
    return '';
  }

  public get messageContactLocation(): Location {
    const eventId = this.eventId;
    const messageContactId = this.messageContactId;
    let targetRoute: Location = {
      name: 'promo-page-contacts-contact',
      params: {
        eventId: '' + eventId,
        contact_id: '' + messageContactId
      }
    };
    if (this.$route.name === 'text-chat-expanded') {
      targetRoute = {
        name: 'text-chat-expanded',
        params: {
          eventId: '' + eventId,
          textChatId: this.$route.params.textChatId,
          contact_id: '' + messageContactId
        }
      };
    }
    return (eventId && messageContactId) ? targetRoute : null;
  }

  public get generateLinksWithImagePreviews(): string {
    return ChatHelper.createLinksWithImagePreviews(this.message.message);
  }

}
