


import Component from 'vue-class-component';
import { Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import EventChatHeader from '@/_modules/event-chat/components/event-chat-header/event-chat-header.vue';
import ChatMessageComposer
  from '@/_modules/chat/components/chat-message-composer/chat-message-composer.vue';
import EventChatContactsList
  from '@/_modules/event-chat/components/event-chat-contacts-list/event-chat-contacts-list.vue';
import { TEvent } from '@/_types/event.type';
import ChatMessages from '@/_modules/chat/components/chat-messages/chat-messages.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';

@Component({
  name: 'event-chat-dialog',
  components: {
    EventChatHeader,
    ChatMessageComposer,
    EventChatContactsList,
    ChatMessages,
  },
  computed: {
    ...mapGetters({
      event: '_eventStore/event',
      getChatGroupStateByGroupId: 'chatStore/getChatGroupStateByGroupId',
    }),
  }
})
export default class EventChatDialog extends Vue {

  public readonly event: TEvent;
  public readonly getChatGroupStateByGroupId: (groupId: string) => TChatGroupState;

  public isContactsListVisible: boolean = false;

  public get chatGroupId(): string {
    return ChatHelper.getEventChatGroupId(this.event);
  }

  public get chatGroupState(): TChatGroupState {
    const chatGroupId = this.chatGroupId;
    return chatGroupId && this.getChatGroupStateByGroupId(chatGroupId);
  }

  public get enterError(): Error {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.enterError) || null;
  }

  public get isEntered(): boolean {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.isEntered) || false;
  }

  public get isMessagesLoading(): boolean {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.isMessagesLoading) || false;
  }

  public get messagesError(): Error {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.messagesError) || null;
  }

  public get isAllMessagesLoaded(): boolean {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.isAllMessagesLoaded) || false;
  }

  public get isCountContactsVisible(): boolean {
    const chatGroupState = this.chatGroupState;
    return chatGroupState && chatGroupState.isContactsRequested && !chatGroupState.isContactsLoading;
  }

  public get contactsError(): Error {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.contactsError) || null;
  }

  public get contactsIds(): number[] {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.contactsIds) || [];
  }

  public get isContactsLoading(): boolean {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.isContactsLoading) || null;
  }

  public get countContacts(): number {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.contactsIds.length) || 0;
  }

  public get messages(): TChatMessage[] {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.messages) || [];
  }

  public get isChatMessageComposerDisabled(): boolean {
    return !this.isEntered || !!this.enterError;
  }

  public async onMessagesScrollTopReached(): Promise<void> {
    const isMessagesLoading = this.isMessagesLoading;
    const isAllMessagesLoaded = this.isAllMessagesLoaded;
    const chatGroupId = this.chatGroupId;

    if (!chatGroupId || isMessagesLoading || isAllMessagesLoaded) {
      return;
    }

    this.$store.dispatch('chatStore/requestChatGroupMessagesPage', chatGroupId);
  }

  public onHeaderHideContactsList(): void {
    this.isContactsListVisible = false;
  }

  public onHeaderShowContactsList(): void {
    this.isContactsListVisible = true;
  }

  public onHeaderClose(): void {
    this.$emit('close');
  }

  @Watch('chatGroupId', { immediate: true })
  private onChatGroupIdChange(): void {
    const chatGroupId = this.chatGroupId;
    const chatGroupState = this.chatGroupState;
    if (chatGroupId && !chatGroupState) {
      this.$store.dispatch('chatStore/enterChatGroup', chatGroupId);
      this.$store.dispatch('chatStore/requestChatGroupMessagesPage', chatGroupId);
      this.$store.dispatch('chatStore/requestChatGroupContacts', chatGroupId);
    }
  }

}
